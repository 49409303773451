import * as React from "react";

import Layout from "../components/Base/Layout";
import RegisterForm from "../components/Registration/RegisterForm";
import { SEO } from "../components/Base/Seo";
import { SvgBackground } from "../components/Base/SvgBackground";
import UnAuthContent from "../components/Login/UnAuthContent";

const Register = () => {
  return (
    <Layout>
        <UnAuthContent>
            <SvgBackground position="absolute" bottom="0" right="0"></SvgBackground>
            <RegisterForm />
      </UnAuthContent>
    </Layout>
  );
}

export default Register

export const Head = () => (
  <SEO title="AceMedical Wholesale | Register" description="Register here to shop for your wholesale medical products." />
)