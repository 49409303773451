export const steps = [
  {
    title: 'Step 1',
    description: 'Email and Password',
    number: "1"
  },
  {
    title: 'Step 2',
    description: 'Address',
    number: "2"
  },
  {
    title: 'Step 3',
    description: 'Medical License',
    number: "3"
  },
]