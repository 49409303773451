import { graphql, useStaticQuery } from "gatsby"

export const getAllCountries = () => {
  const data = useStaticQuery(graphql`
    query {
        wp {
        wooCountries {
        shippingCountries {
            countryCode
            countryName
        }
        }
    }
        }
  `)

  return data.wp.wooCountries.shippingCountries
}