import { Button, FormControl, FormLabel, HStack, Input, Text } from "@chakra-ui/react"
import React, { useState } from 'react'

import validator from 'validator'

function Step3({ handleFormData, values, prevStep, handleSubmit, loading, }: any): any {
  
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  function submitFormData (event: React.FormEvent<HTMLFormElement>){
    event.preventDefault();

    if ( ! validator.isLength(values.company, {min: 2, max: 100})) {
      setError(true);
    } else {
        handleSubmit()
    }
  };
  return (
    <form onSubmit={submitFormData}>
      <HStack  pb="24px" flexFlow={{base: "column", md: "row"}}>
        <FormControl>
          <FormLabel htmlFor="company"> Company Name </FormLabel>
            <Input id="company" placeholder="Company Name" type="text" name="company" 
            onChange={handleFormData("company")}
                      defaultValue={values.company}
            height="32px" 
            required
            />
        </FormControl>
          
        <FormControl marginTop={{base: "8px !important", md: "0px !important"}} marginInlineStart={{base: "0px !important", md:"6 !important"}}>
          <FormLabel htmlFor="medical">Medical Specialisation</FormLabel>
            <Input id="medical" placeholder="Medical Specialisation" type="text" name="medical"onChange={handleFormData("medical")} defaultValue={values.medical} height="32px" required />
        </FormControl>

      </HStack>

      <HStack flexFlow={{base: "column", md: "row"}}>
        <FormControl>
          <FormLabel htmlFor="mln">Medical License Number</FormLabel>
          <Input id="mln" required placeholder="Medical License Number" type="number" name="mln" onChange={handleFormData("mln")} defaultValue={values.mln} height="32px" />
           {error ? (
                <Text style={{ color: "red" }}>
                  Medical Licence Number not long enough
                </Text>
              ) : (
                ""
              )}
        </FormControl>
            
        <FormControl marginTop={{base: "8px !important", md: "0px !important"}} marginInlineStart={{base: "0px !important", md:"6 !important"}}>
          <FormLabel htmlFor="date"> Expiration Date </FormLabel>
            <Input id="date" placeholder="DD / MM / YYYY" type="date" name="date" required
          onChange={handleFormData("date")}
                      defaultValue={values.date}
            height="32px"
            />  
        </FormControl>

         
      </HStack>
       <HStack py="24px" justifyContent="flex-end">
           <Button onClick={prevStep} variant="ghost" size="lg" > Prev </Button>
          <Button type="submit" size="lg"> {loading ? "Loading" : "Finish" } </Button>
      </HStack>
    </form>
  )
}

export default Step3