import { Box, Button, Container, Grid, HStack, Heading, Link, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { gql, useMutation } from "@apollo/client";

import { Step } from './Step'
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import { navigate } from "gatsby";
import { steps } from './data'

const REGISTER_USER = gql`
  mutation MyMutation(
    $address1: String  
    $address2: String  
    $email: String  
    $firstName: String
    $lastName: String  
    $password: String  
    $city: String 
    $company: String  
    $postcode: String 
    $state: String
    $country: CountriesEnum
  ) {
    registerCustomer(
      input: {
        billing: {
          address1: $address1, 
          address2: $address2, 
          city: $city, 
          company: $company, 
          postcode: $postcode, 
          state: $state
          country: $country
        }, 
        email: $email, 
        firstName: $firstName, 
        lastName: $lastName, 
        password: $password,
        nickname: $state
      }
    ) {
    customer {
      databaseId
      firstName
    }
  }
  }
`;

export default function Register() {

  // Displaying different form parts based on step
  const PageDisplay = () => {
    if (currentStep === 0) {
      return <Step1 handleFormData={handleInputData} values={formData} nextStep={nextStep}></Step1>
    } else if (currentStep === 1) {
      return <Step2 handleFormData={handleInputData} values={formData} nextStep={nextStep} prevStep={prevStep}></Step2>
    } else {
      return <Step3 handleFormData={handleInputData} values={formData} handleSubmit={handleSubmit} prevStep={prevStep} loading={loading} ></Step3>
    }
  }

  // Saving Form data 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postcode: "",
    country: "AF",
    company: ""
  })

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input: any) => (e: any) => {
    // input value from the form
    const values = e.currentTarget.value;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: values
    }));
  }

  // Register User

  const [register, { data, loading, error }] = useMutation(REGISTER_USER);
  const wasSignUpSuccessful = Boolean(data?.registerCustomer?.customer?.firstName);

  const toast = useToast()

  function handleSubmit() {

    register({
      variables: formData,
    }).catch(error => {

      if (error == "Error: Internal server error") {
        navigate('/ty-for-registration');
      }

      // useToast({
      //   title: error.message,
      //   status: "error"
      // })
    });

  }

  if (wasSignUpSuccessful) {
    navigate('/ty-for-registration');
  }

  const [currentStep, setStep] = useState(0)

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(currentStep + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(currentStep - 1);
  };
  
  return (
    <SimpleGrid columns={{base: 1, lg: 4}} px="50px">
      <HStack display={{base: "none", lg: "flex"}} >
        <Box width="320px">
      
          <Text color="gray.500">All shipments are experess delivery, delivered directly to you within 3-5 business days. </Text>
            <br></br>
          <Text color="gray.500">All cold chain products are shipped in temperature controlled coolers.</Text>
            <br></br>
          <Text color="gray.500">Major discounts available depending on quantity of orders. </Text>
            <br></br>
          <Text color="gray.500">Customer service available 24/7. Simply call or send us an email. </Text>
            <br></br>
          <Text color="yellow.600" fontWeight="700"> <a href="tel:1800-465-1525"> 1800-465-1525 </a></Text>
          <Text color="yellow.600" fontWeight="700"> <a href="mailto:Info@acemedicalwholesale.com">Info@acemedicalwholesale.com</a></Text>
          
        </Box>
      </HStack>
      
      <Box position="relative">
        <Container minW={{base: "auto", md:"660px"}} py={10} px="0" >
          <Box>
            <Text fontSize="md" color="yellow.500" pb="12px">Welcome</Text>
            <Heading fontSize="4xl" fontWeight="400" lineHeight={10} > Customer Registration </Heading>
            <Text fontSize="md" py="20px" pb="32px" color="gray.500" >Please note that only licensed medical practitioners can purchase products from AceMedical.</Text>
            <Box bg="white" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
              <Stack p="6" pb="24px" direction='row' >
                {steps.map((step, id) => (
                  <Step
                    key={id}
                    cursor="pointer"
                    onClick={() => setStep(id)}
                    title={step.title}
                    description={step.description}
                    isActive={currentStep === id}
                    isCompleted={currentStep > id}
                    isFirstStep={id === 0}
                    isLastStep={steps.length === id + 1}
                    number={step.number}
                  />
                ))}
              </Stack>

              <Box px="24px">

                {PageDisplay()}

                {error ? (
                  (error.message.includes('An account is already registered with your email address. <a href="#" class="showlogin">Please log in.</a>') ? (
                    <Text color="red" textAlign="center">
                      You&#39;re already signed up! <Link href="/log-in" color="gray.800" textDecoration="underline">Log in</Link>
                    </Text>
                  ) : (
                    <Text color="red">{error.message}</Text>
                  ))
                ) : null}

              </Box>
            </Box>
              <Text fontSize="sm" color="gray.500" py="52px" >Your account will be verified within one business day.  We’ll notify you and answer any questions you may have, and make sure you have a positive, rewarding experience.</Text>
          </Box>
        
        </Container>
      </Box>
    </SimpleGrid>
  )
}

